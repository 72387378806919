import browserUpdate from "browser-update/update.npm";
import "focus-visible/dist/focus-visible";
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';

// See : https://github.com/browser-update/browser-update/wiki/Details-on-configuration
browserUpdate({
  required: {
    e: 17, // Edge
    i: 11, // IE
    f: 76, // Firefox
    s: 12, // Safari
    c: 80, // Chrome
  },
  insecure: true,
});

// See : https://github.com/jonathantneal/svg4everybody
svg4everybody();

// See : https://github.com/fregante/object-fit-images
objectFitImages();
